import React, { useState, useEffect } from 'react';
import { Tabs, Table, notification, Button, Drawer } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import './App.css';
import WheelOfFortune from "./components/wheel/WheelOfFortune";
import TagInput from "./components/tag-input/TagInput";

function App() {
    const [tags, setTags] = useState(["Manchester United", "Milan", "Real Madrid", "Barcelona"]);
    const [winCounts, setWinCounts] = useState({});
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth < 600);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const initialCounts = tags.reduce((acc, tag) => ({ ...acc, [tag]: 0 }), {});
        setWinCounts(initialCounts);
    }, [tags]);

    const handleAddTag = (newTag) => {
        if (newTag && !tags.includes(newTag)) {
            setTags([...tags, newTag]);
            setWinCounts((prevCounts) => ({ ...prevCounts, [newTag]: 0 }));
        }
    };

    const handleRemoveTag = (tagToRemove) => {
        setTags(tags.filter(tag => tag !== tagToRemove));
        setWinCounts((prevCounts) => {
            const updatedCounts = { ...prevCounts };
            delete updatedCounts[tagToRemove];
            return updatedCounts;
        });
    };

    const handleWin = (winningTag) => {
        setWinCounts((prevCounts) => ({
            ...prevCounts,
            [winningTag]: (prevCounts[winningTag] || 0) + 1
        }));

        notification.open({
            message: `Kazanan: ${winningTag}`,
            placement: 'bottom',
            description: `Çarkta kazanan ${winningTag} oldu!`,
            btn: (
                <div style={{ display: 'flex', gap: '10px' }}>
                    <Button type="primary" onClick={() => notification.destroy()}>
                        Kapat
                    </Button>
                    <Button danger onClick={() => {
                        handleRemoveTag(winningTag);
                        notification.destroy();
                    }}>
                        Sil
                    </Button>
                </div>
            ),
            onClose: () => console.log('Notification kapatıldı'),
        });
    };

    const columns = [
        {
            title: 'Etiket',
            dataIndex: 'tag',
            key: 'tag',
        },
        {
            title: 'Kazanma Sayısı',
            dataIndex: 'count',
            key: 'count',
        },
    ];

    const dataSource = Object.entries(winCounts).map(([tag, count]) => ({
        key: tag,
        tag,
        count,
    }));

    // Tabs'in items yapısını kullanarak sekmeleri tanımlıyoruz
    const tabItems = [
        {
            key: '1',
            label: 'Etiketler',
            children: <TagInput tags={tags} onAddTag={handleAddTag} onRemoveTag={handleRemoveTag} />
        },
        {
            key: '2',
            label: 'Sayaç',
            children: <Table columns={columns} dataSource={dataSource} pagination={false} />
        },
    ];

    return (
        <div className="app-container">
            {/* Hamburger icon for mobile */}
            {isMobile && (
                <Button
                    icon={<MenuOutlined />}
                    type="primary"
                    shape="circle"
                    onClick={() => setIsDrawerVisible(true)}
                    style={{ position: 'absolute', top: 20, left: 20, zIndex: 1000 }}
                />
            )}

            <div className="wheel-section">
                <WheelOfFortune tags={tags} onWin={handleWin} />
            </div>

            {/* Drawer for mobile */}
            <Drawer
                title="Options"
                placement="left"
                onClose={() => setIsDrawerVisible(false)}
                open={isDrawerVisible}
                width={300}
            >
                <Tabs defaultActiveKey="1" items={tabItems} />
            </Drawer>

            {/* Sidebar for desktop */}
            {!isMobile && (
                <div className="features-section">
                    <Tabs defaultActiveKey="1" items={tabItems} />
                </div>
            )}
        </div>
    );
}

export default App;

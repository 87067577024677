import React, { useState } from 'react';
import { Input, Tag } from 'antd';

const TagInput = ({ tags, onAddTag, onRemoveTag }) => {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => setInputValue(e.target.value);

    const handleInputConfirm = () => {
        if (inputValue) {
            const capitalizedTag = inputValue.charAt(0).toUpperCase() + inputValue.slice(1).toLowerCase(); // Capitalize işlemi
            onAddTag(capitalizedTag);
            setInputValue('');
        }
    };

    return (
        <div className="tag-input-container">
            {tags.map((tag) => (
                <Tag
                    className="tag-input"
                    key={tag}
                    closable
                    onClose={() => onRemoveTag(tag)}
                >
                    {tag}
                </Tag>
            ))}
            <Input
                type="text"
                placeholder="Etiket ekleyin ve Enter’a basın"
                value={inputValue}
                onChange={handleInputChange}
                onPressEnter={handleInputConfirm}
                style={{ width: 200, marginTop: 8 }}
            />
        </div>
    );
};

export default TagInput;

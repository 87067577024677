import React, { useRef, useState, useEffect, useCallback } from 'react';
import { gsap } from 'gsap';
import './WheelOfFortune.css';
import { SyncOutlined } from '@ant-design/icons';
import { Button } from "antd";

// Function to truncate text to a specific width
const truncateText = (ctx, text, maxWidth) => {
    if (ctx.measureText(text).width <= maxWidth) {
        return text;
    }
    while (ctx.measureText(text + '...').width > maxWidth) {
        text = text.slice(0, -1);
    }
    return text + '...';
};

const WheelOfFortune = ({ tags, onWin }) => {
    const canvasRef = useRef(null);
    const pointerRef = useRef(null);
    const [isSpinning, setIsSpinning] = useState(false);
    const [currentRotation, setCurrentRotation] = useState(0);
    const [radius, setRadius] = useState(235); // Default radius for desktop (470px diameter / 2)

    const segmentAngle = 360 / (tags.length || 1);

    // Set radius based on screen width
    useEffect(() => {
        const updateRadius = () => {
            const screenWidth = window.innerWidth;
            const maxDiameter = 470;
            const diameter = screenWidth <= 520 ? screenWidth - 50 : maxDiameter;
            setRadius(diameter / 2);
        };

        updateRadius();
        window.addEventListener('resize', updateRadius);
        return () => window.removeEventListener('resize', updateRadius);
    }, []);

    // Apply pointer shake effect
    const animatePointer = () => {
        gsap.fromTo(
            pointerRef.current,
            { rotation: 90 },
            { rotation: 80, duration: 0.1, yoyo: true, repeat: 1 }
        );
    };

    // Canvas drawing function
    const drawWheel = useCallback((rotationAngle) => {
        const canvas = canvasRef.current;
        if (!canvas) return;

        const ctx = canvas.getContext('2d');
        const maxTextWidth = radius * 0.75;

        canvas.width = radius * 2 + 20;
        canvas.height = radius * 2 + 20;

        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.save();
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((rotationAngle * Math.PI) / 180);

        // Draw outer circle (orange fill, black border)
        ctx.beginPath();
        ctx.arc(0, 0, radius + 8, 0, Math.PI * 2);
        ctx.fill();
        ctx.lineWidth = 4;
        ctx.strokeStyle = 'black';
        ctx.stroke();

        tags.forEach((tag, index) => {
            const startAngle = (index * segmentAngle * Math.PI) / 180;
            const endAngle = ((index + 1) * segmentAngle * Math.PI) / 180;
            const middleAngle = startAngle + (endAngle - startAngle) / 2;

            // Draw segment
            ctx.beginPath();
            ctx.moveTo(0, 0);
            ctx.arc(0, 0, radius, startAngle, endAngle);
            ctx.fillStyle = `hsl(${index * (360 / tags.length)}, 90%, 50%)`;
            ctx.fill();

            // Add small yellow pins along the segment
            const pinRadius = radius + 5;
            [startAngle, middleAngle, endAngle].forEach((angle) => {
                ctx.save();
                ctx.rotate(angle);
                ctx.translate(pinRadius, 0);
                ctx.beginPath();
                ctx.arc(0, 0, 3, 0, Math.PI * 2);
                ctx.fillStyle = '#fbd900';
                ctx.fill();
                ctx.restore();
            });

            // Position text in the center of each segment
            ctx.save();
            ctx.fillStyle = 'white';
            ctx.font = 'bold 14px Arial';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            ctx.rotate(middleAngle);
            ctx.translate(radius * 0.6, 0);
            const truncatedText = truncateText(ctx, tag, maxTextWidth);
            ctx.fillText(truncatedText, 0, 0);
            ctx.restore();
        });

        // Draw a light gray circle in the center with shadow
        ctx.save();
        ctx.shadowColor = 'rgba(0, 0, 0, 0.3)';
        ctx.shadowBlur = 8;
        ctx.shadowOffsetX = 3;
        ctx.shadowOffsetY = 3;
        ctx.beginPath();
        ctx.arc(0, 0, radius / 8, 0, Math.PI * 2);
        ctx.fillStyle = '#f0f0f0';
        ctx.fill();
        ctx.restore();

        ctx.restore();
    }, [tags, segmentAngle, radius]);

    // Spin function
    const spinWheel = () => {
        if (isSpinning) return;
        setIsSpinning(true);

        const randomSpin = 360 * 5 + Math.floor(Math.random() * 360);
        const targetRotation = currentRotation + randomSpin;

        const animation = { rotation: currentRotation };

        gsap.to(animation, {
            rotation: targetRotation,
            duration: 5,
            ease: "power4.out",
            onUpdate() {
                drawWheel(animation.rotation);
                if (Math.floor(animation.rotation % segmentAngle) === 0) {
                    animatePointer();
                }
            },
            onComplete() {
                setCurrentRotation(targetRotation % 360);
                setIsSpinning(false);
                const winningIndex = Math.floor((360 - (targetRotation % 360)) / segmentAngle) % tags.length;
                onWin(tags[winningIndex]); // Pass the winning tag
            },
        });
    };

    useEffect(() => {
        if (canvasRef.current) {
            drawWheel(currentRotation);
        }
    }, [currentRotation, drawWheel]);

    return (
        <div className="container">
            <div className="wheel-section">
                <div className="wheel-container">
                    <div className="wheel-wrapper">
                        <div
                            className="wheel-pointer" ref={pointerRef}
                        />
                        <canvas ref={canvasRef}/>
                    </div>
                    <Button icon={<SyncOutlined/>} className={'spin-button'} type={'primary'} shape={'round'}
                            iconPosition={'end'} onClick={spinWheel} disabled={isSpinning || tags.length < 2}>
                        Çarkı Çevir
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default WheelOfFortune;
